import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  where,
  addDoc,
} from "firebase/firestore";
import { auth, db, storage } from "../firebase";
import { TextField, Typography, Box, Modal, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import ViewInvoiceModal from "./modals/ViewInvoiceModal";
import EditInvoiceModal from "./modals/EditInvoiceModal";
import InvoiceUpload from "./InvoiceUpload";
import InvoiceActions from "./InvoiceActions";
import InvoiceTable from "./InvoiceTable";
import FilterControls from "./FilterControls";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
} from "firebase/storage";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { processFile } from "../utils/extraction"; // Import the new processFile function

export interface Invoice {
  id: string;
  name: string;
  fileUrl: string;
  fileName: string;
  date: string;
  invoiceNumber: number;
  category?: string;
  subtotal: number;
  vatTotal: number;
  total: number;
  clientName?: string; // Optional client name
  dateScanned?: string; // Optional date scanned
}

interface InvoicesProps {
  type: "purchase" | "sales" | "expense";
}

const Invoices: React.FC<InvoicesProps> = ({ type }) => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [modalType, setModalType] = useState<"view" | "edit" | "upload">(
    "view"
  );
  const [filter, setFilter] = useState<string>("");
  const [sortConfig, setSortConfig] = useState<{
    key: keyof Invoice;
    direction: "ascending" | "descending";
  } | null>(null);

  const [yearFilter, setYearFilter] = useState<string>("");
  const [monthFilter, setMonthFilter] = useState<string>("");
  const [quarterFilter, setQuarterFilter] = useState<string>("");

  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);

  const fetchInvoices = async () => {
    if (modalIsOpen && modalType === "upload") {
      closeModal();
    }
    const user = auth.currentUser;
    if (!user) {
      return;
    }
    const userId = user.uid;
    const collectionName =
      type === "purchase"
        ? "purchaseInvoices"
        : type === "sales"
        ? "salesInvoices"
        : "expenseInvoices";
    const q = query(collection(db, collectionName, userId, "userInvoices"));
    const querySnapshot = await getDocs(q);
    setInvoices(
      querySnapshot.docs.map(
        (doc) =>
          ({
            id: doc.id,
            subtotal: 0,
            vatTotal: 0,
            total: 0,
            ...doc.data(),
          } as Invoice) // Default subtotal, vatTotal, and total to 0
      )
    );
    console.log(invoices);
  };

  useEffect(() => {
    fetchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const openModal = (
    type: "view" | "edit" | "upload",
    invoice: Invoice | null = null
  ) => {
    setSelectedInvoice(invoice);
    setModalType(type);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedInvoice(null);
  };

  const handleFileNameChange = async (
    invoice: Invoice,
    newName: string,
    newCategory: string,
    newInvoiceNumber: number,
    newDate: string,
    newSubtotal: number,
    newVatTotal: number,
    newTotal: number,
    newClientName: string
  ) => {
    const user = auth.currentUser;
    if (!user) return;
    const userId = user.uid;

    const collectionName =
      type === "purchase"
        ? "purchaseInvoices"
        : type === "sales"
        ? "salesInvoices"
        : "expenseInvoices";

    // Check if the client exists and add if not
    const clientCollection = collection(db, "clients", userId, "userClients");
    const clientQuery = query(
      clientCollection,
      where("name", "==", newClientName)
    );
    const clientSnapshot = await getDocs(clientQuery);

    if (clientSnapshot.empty && newClientName !== "") {
      await addDoc(clientCollection, {
        name: newClientName,
        vatNumber: "", // You may want to capture these details as well
        address: "", // You may want to capture these details as well
        contactInfo: "", // You may want to capture these details as well
      });
    }

    // Update Firestore entry only
    const invoiceDoc = doc(
      db,
      collectionName,
      userId,
      "userInvoices",
      invoice.id
    );
    await updateDoc(invoiceDoc, {
      name: newName,
      category: newCategory,
      invoiceNumber: newInvoiceNumber,
      date: newDate,
      subtotal: newSubtotal,
      vatTotal: newVatTotal,
      total: newTotal,
      clientName: newClientName || "Unknown", // Update client name
    });

    const updatedInvoices = invoices.map((inv) =>
      inv.id === invoice.id
        ? {
            ...inv,
            name: newName,
            category: newCategory,
            invoiceNumber: newInvoiceNumber,
            date: newDate,
            subtotal: newSubtotal,
            vatTotal: newVatTotal,
            total: newTotal,
            clientName: newClientName, // Update client name
          }
        : inv
    );
    setInvoices(updatedInvoices);
    closeModal();
  };

  const handleDelete = async () => {
    const confirmResult = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (!confirmResult.isConfirmed) {
      return;
    }

    const user = auth.currentUser;
    if (!user) return;
    const userId = user.uid;

    const collectionName =
      type === "purchase"
        ? "purchaseInvoices"
        : type === "sales"
        ? "salesInvoices"
        : "expenseInvoices";

    const storage = getStorage();

    for (const invoiceId of selectedInvoices) {
      const invoice = invoices.find((inv) => inv.id === invoiceId);

      if (invoice) {
        try {
          const fileRef = ref(
            storage,
            `${collectionName}/${userId}/${invoice.fileName}`
          );
          await deleteObject(fileRef);
        } catch (error) {
          console.error("Error deleting file from Firebase Storage:", error);
        }

        // Delete the document from Firestore
        try {
          const invoiceDoc = doc(
            db,
            collectionName,
            userId,
            "userInvoices",
            invoice.id
          );
          await deleteDoc(invoiceDoc);

          // Update local state
          setInvoices((prevInvoices) =>
            prevInvoices.filter((inv) => inv.id !== invoice.id)
          );
        } catch (error) {
          console.error(
            "Error deleting invoice document from Firestore:",
            error
          );
        }
      }
    }

    Swal.fire("Deleted!", "Selected invoices have been deleted.", "success");
    setSelectedInvoices([]);
  };

  const downloadInvoice = async (invoice: Invoice) => {
    const link = document.createElement("a");
    link.href = invoice.fileUrl;
    link.download = getFileNameWithExtension(invoice.fileName);
    console.log(link.download);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const getFileNameWithExtension = (fileName: string) => {
    console.log(fileName);

    if (!fileName.includes(".")) {
      return `${fileName}.png`;
    }
    return fileName;
  };
  const downloadAllInvoices = async () => {
    const zip = new JSZip();
    const user = auth.currentUser;
    if (!user) return;

    const userId = user.uid;
    const collectionName =
      type === "purchase"
        ? "purchaseInvoices"
        : type === "sales"
        ? "salesInvoices"
        : "expenseInvoices";

    for (const invoiceId of selectedInvoices) {
      const invoice = invoices.find((inv) => inv.id === invoiceId);
      if (invoice) {
        try {
          const fileRef = ref(
            storage,
            `${collectionName}/${userId}/${invoice.fileName}`
          );

          const fileUrl = await getDownloadURL(fileRef);
          const response = await fetch(fileUrl);
          const blob = await response.blob();
          zip.file(getFileNameWithExtension(invoice.fileName), blob);
        } catch (error) {
          console.error(`Error fetching file ${invoice.fileName}:`, error);
          continue;
        }
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${type}_invoices.zip`);
    });
  };

  const handleRescan = async () => {
    const user = auth.currentUser;
    if (!user) {
      Swal.fire("Error", "No user logged in", "error");
      return;
    }

    Swal.fire({
      title: "Rescanning invoices...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      for (const invoiceId of selectedInvoices) {
        const invoice = invoices.find((inv) => inv.id === invoiceId);
        if (invoice) {
          const response = await fetch(invoice.fileUrl);
          const blob = await response.blob();
          const file = new File([blob], invoice.fileName);

          await processFile(file, user, db, type, true, invoice.id);
        }
      }
      Swal.fire(
        "Success",
        "Selected invoices rescanned successfully",
        "success"
      );
      fetchInvoices();
    } catch (error) {
      console.error("Error during rescan process:", error);
      Swal.fire(
        "Error",
        "An error occurred during the rescan process",
        "error"
      );
    } finally {
      setSelectedInvoices([]);
    }
  };

  const handleSelectInvoice = (invoiceIds: string[]) => {
    setSelectedInvoices(invoiceIds);
  };

  const filteredInvoices = invoices.filter((invoice) => {
    const [day, month, year] = invoice.date.split("/");
    const invoiceDate = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day)
    );

    const filterByYear = yearFilter
      ? invoiceDate.getFullYear().toString() === yearFilter
      : true;
    const filterByMonth = monthFilter
      ? (invoiceDate.getMonth() + 1).toString() === monthFilter
      : true;
    const filterByQuarter = quarterFilter
      ? Math.ceil((invoiceDate.getMonth() + 1) / 3).toString() === quarterFilter
      : true;

    const filterText = filter.toLowerCase();
    const matchesFilter =
      invoice.fileName.toLowerCase().includes(filterText) ||
      (invoice.clientName &&
        invoice.clientName.toLowerCase().includes(filterText)) ||
      invoice.date.toLowerCase().includes(filterText) ||
      invoice.total.toString().includes(filterText);

    return matchesFilter && filterByYear && filterByMonth && filterByQuarter;
  });

  const sortedInvoices = React.useMemo(() => {
    let sortableInvoices = [...filteredInvoices];
    if (sortConfig !== null) {
      sortableInvoices.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (aValue === undefined || bValue === undefined) {
          return 0;
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableInvoices;
  }, [filteredInvoices, sortConfig]);

  const requestSort = (key: keyof Invoice) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key: keyof Invoice) => {
    if (sortConfig && sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return "";
  };

  const totalSubtotal = sortedInvoices.reduce(
    (sum, invoice) => sum + invoice.subtotal,
    0
  );
  const totalVat = sortedInvoices.reduce(
    (sum, invoice) => sum + invoice.vatTotal,
    0
  );
  const totalAmount = sortedInvoices.reduce(
    (sum, invoice) => sum + invoice.total,
    0
  );

  return (
    <div className="bg-white shadow-md rounded p-4">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">
          {type === "purchase"
            ? "Purchase Invoices"
            : type === "sales"
            ? "Sales Invoices"
            : "Expense Invoices"}
        </Typography>
        <IconButton color="primary" onClick={() => openModal("upload")}>
          <AddIcon />
        </IconButton>
      </Box>
      <TextField
        label="Filter"
        variant="outlined"
        fullWidth
        margin="normal"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
      <FilterControls
        yearFilter={yearFilter}
        setYearFilter={setYearFilter}
        monthFilter={monthFilter}
        setMonthFilter={setMonthFilter}
        quarterFilter={quarterFilter}
        setQuarterFilter={setQuarterFilter}
      />
      <InvoiceActions
        selectedInvoices={selectedInvoices}
        onRescan={handleRescan}
        onEdit={() =>
          selectedInvoices.length === 1 &&
          openModal(
            "edit",
            invoices.find((inv) => inv.id === selectedInvoices[0])
          )
        }
        onDelete={handleDelete}
        onDownload={downloadAllInvoices}
      />
      <InvoiceTable
        invoices={sortedInvoices}
        selectedInvoices={selectedInvoices}
        onSelectInvoice={handleSelectInvoice}
        onOpenModal={openModal}
        requestSort={requestSort}
        getSortIcon={getSortIcon}
        type={type}
      />
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Typography variant="h6">
          Totals - Subtotal: {totalSubtotal.toFixed(2)}, VAT:{" "}
          {totalVat.toFixed(2)}, Total: {totalAmount.toFixed(2)}
        </Typography>
      </Box>
      <ViewInvoiceModal
        open={modalIsOpen && modalType === "view"}
        onClose={closeModal}
        invoice={selectedInvoice}
        onDownload={downloadInvoice}
      />

      <EditInvoiceModal
        open={modalIsOpen && modalType === "edit"}
        onClose={closeModal}
        invoice={selectedInvoice}
        onSave={handleFileNameChange}
      />

      <Modal
        open={modalIsOpen && modalType === "upload"}
        onClose={closeModal}
        aria-labelledby="upload-invoice-modal-title"
        aria-describedby="upload-invoice-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            id="upload-invoice-modal-title"
            variant="h6"
            component="h2"
          >
            {type === "purchase"
              ? "Upload Purchase Invoice"
              : type === "sales"
              ? "Upload Sales Invoice"
              : "Upload Expense Invoice"}
          </Typography>
          <InvoiceUpload type={type} onUploadSuccess={fetchInvoices} />
        </Box>
      </Modal>
    </div>
  );
};

export default Invoices;
