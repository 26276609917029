import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import { Invoice } from "./Invoices";

interface InvoiceTableProps {
  invoices: Invoice[];
  selectedInvoices: string[];
  onSelectInvoice: (invoiceIds: string[]) => void;
  onOpenModal: (
    type: "view" | "edit" | "upload",
    invoice: Invoice | null
  ) => void;
  requestSort: (key: keyof Invoice) => void;
  getSortIcon: (key: keyof Invoice) => string;
  type: "purchase" | "sales" | "expense"; // Add type prop here
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({
  invoices,
  selectedInvoices,
  onSelectInvoice,
  onOpenModal,
  requestSort,
  getSortIcon,
  type, // Add type prop here
}) => {
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = invoices.map((invoice) => invoice.id);
      onSelectInvoice(newSelecteds);
    } else {
      onSelectInvoice([]);
    }
  };

  const handleClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const selectedIndex = selectedInvoices.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedInvoices, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedInvoices.slice(1));
    } else if (selectedIndex === selectedInvoices.length - 1) {
      newSelected = newSelected.concat(selectedInvoices.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedInvoices.slice(0, selectedIndex),
        selectedInvoices.slice(selectedIndex + 1)
      );
    }

    onSelectInvoice(newSelected);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={
                  selectedInvoices.length > 0 &&
                  selectedInvoices.length < invoices.length
                }
                checked={
                  invoices.length > 0 &&
                  selectedInvoices.length === invoices.length
                }
                onChange={handleSelectAllClick}
              />
            </TableCell>
            <TableCell
              onClick={() => requestSort("invoiceNumber")}
              style={{ cursor: "pointer" }}
            >
              Invoice Number {getSortIcon("invoiceNumber")}
            </TableCell>
            <TableCell
              onClick={() => requestSort("name")}
              style={{ cursor: "pointer" }}
            >
              Invoice Name {getSortIcon("name")}
            </TableCell>
            <TableCell
              onClick={() => requestSort("date")}
              style={{ cursor: "pointer" }}
            >
              Date {getSortIcon("date")}
            </TableCell>
            <TableCell
              onClick={() => requestSort("dateScanned")}
              style={{ cursor: "pointer" }}
            >
              Scan Date {getSortIcon("dateScanned")}
            </TableCell>

            <TableCell
              onClick={() => requestSort("clientName")}
              style={{ cursor: "pointer" }}
            >
              {type === "purchase" || type === "expense"
                ? "Supplier Name"
                : "Client Name"}{" "}
              {getSortIcon("clientName")}
            </TableCell>
            <TableCell
              onClick={() => requestSort("category")}
              style={{ cursor: "pointer" }}
            >
              Category {getSortIcon("category")}
            </TableCell>
            <TableCell
              onClick={() => requestSort("total")}
              style={{ cursor: "pointer" }}
            >
              Total {getSortIcon("total")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice) => (
            <TableRow key={invoice.id}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedInvoices.includes(invoice.id)}
                  onChange={(event) => handleClick(event, invoice.id)}
                />
              </TableCell>
              <TableCell>{invoice.invoiceNumber}</TableCell>
              <TableCell>
                <button
                  onClick={() => onOpenModal("view", invoice)}
                  className="text-blue-500 hover:underline"
                >
                  {invoice.name
                    ? invoice.name
                    : `Invoice_${invoice.invoiceNumber}`}
                </button>
              </TableCell>
              <TableCell>{invoice.date}</TableCell>
              <TableCell>{invoice.dateScanned}</TableCell>

              <TableCell>{invoice.clientName}</TableCell>
              <TableCell>{invoice.category}</TableCell>
              <TableCell>{invoice.total?.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;
