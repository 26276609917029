import React, { FC, useEffect, useState } from "react";
import { pdf } from "@react-pdf/renderer";
import { Invoice, TInvoice } from "../data/types";
import { useDebounce } from "@uidotdev/usehooks";
import InvoicePage from "./InvoicePage";
import { auth, storage, db } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import {
  Typography,
  TextField,
  Button,
  Modal,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import {
  collection,
  addDoc,
  query,
  orderBy,
  getDocs,
  limit,
} from "firebase/firestore";
import moment from "moment";
interface Props {
  data: Invoice;
  setData(data: Invoice): void;
}

const Download: FC<Props> = ({ data, setData }) => {
  const debounced = useDebounce(data, 500);
  // console.log(debounced);

  const [templates, setTemplates] = useState<{ name: string; url: string }[]>(
    []
  );
  const [templateName, setTemplateName] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (data.invoiceTitle) {
      setTemplateName(data.invoiceTitle.toLowerCase());
    }
    fetchTemplates();
    setInvoiceTitleWithNextNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.invoiceTitle]);

  async function fetchTemplates() {
    const user = auth.currentUser;
    if (!user) return;
    const userId = user.uid;

    const storageRef = ref(storage, `templates/${userId}/`);

    try {
      const res = await listAll(storageRef);
      const urls = await Promise.all(
        res.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return { name: itemRef.name, url };
        })
      );
      setTemplates(urls);
    } catch (error) {
      console.error("Error listing templates:", error);
    }
  }

  async function setInvoiceTitleWithNextNumber() {
    const user = auth.currentUser;
    if (!user) return;
    const userId = user.uid;
    const nextInvoiceNumber = await getNextInvoiceNumber(userId);
    data.invoiceTitle = "Inv-" + nextInvoiceNumber;
    // setData((prevData: Invoice) => ({
    //   ...prevData,
    //   invoiceTitle: `Inv-${nextInvoiceNumber}`,
    // }));
  }

  async function handleSaveTemplate() {
    const user = auth.currentUser;
    if (!user) return;

    const userId = user.uid;
    const blob = new Blob([JSON.stringify(debounced)], {
      type: "text/plain;charset=utf-8",
    });
    let fileName = templateName ? templateName.toLowerCase() : "invoice";
    let storageRef = ref(storage, `templates/${userId}/${fileName}.template`);

    // Check if a template with the same name exists and create a unique name
    const res = await listAll(ref(storage, `templates/${userId}/`));
    const existingNames = res.items.map((item) => item.name);
    let counter = 1;
    while (existingNames.includes(`${fileName}.template`)) {
      fileName = `${templateName.toLowerCase()}_${counter}`;
      storageRef = ref(storage, `templates/${userId}/${fileName}.template`);
      counter++;
    }

    try {
      await uploadBytes(storageRef, blob);
      alert("Template saved successfully!");
      fetchTemplates(); // Refresh the template list
      setModalOpen(false); // Close the modal after saving
    } catch (error) {
      console.error("Error saving template:", error);
      alert("Failed to save the template. Please try again.");
    }
  }

  async function handleSavePDF() {
    const user = auth.currentUser;
    if (!user) return;

    const userId = user.uid;
    console.log(debounced);

    const doc = <InvoicePage pdfMode={true} data={debounced} />;
    const pdfBlob = await pdf(doc).toBlob();

    // Calculate totals
    let subTotalSum = 0;
    let vatTotalSum = 0;
    let totalSum = 0;
    const vatRate = parseFloat(data.taxLabel.match(/(\d+)%/)?.[1] || "0") / 100;

    data.productLines.forEach((line) => {
      const quantity = parseFloat(line.quantity.replace(/[^\d.-]/g, "")) || 0;
      const rate = parseFloat(line.rate.replace(/[^\d.-]/g, "")) || 0;
      const subTotal = quantity * rate;
      const vatTotal = subTotal * vatRate;
      const total = subTotal + vatTotal;
      subTotalSum += subTotal;
      vatTotalSum += vatTotal;
      totalSum += total;
    });
    subTotalSum = Math.round(subTotalSum * 100) / 100;
    vatTotalSum = Math.round(vatTotalSum * 100) / 100;
    totalSum = Math.round(totalSum * 100) / 100;
    let invoiceNumber = await getNextInvoiceNumber(userId);

    let invoiceDate = moment(data.invoiceDate, "DD, MMM YYYY").format(
      "DD/MM/YYYY"
    );
    console.log(invoiceDate);

    let fileName = `Invoice_${invoiceNumber}_${invoiceDate
      .replace("/", "_")
      .replace("/", "_")}.pdf`;
    console.log(fileName);

    let storageRef = ref(storage, `salesInvoices/${userId}/${fileName}`);

    // Check if a file with the same name exists and create a unique name
    const res = await listAll(ref(storage, `salesInvoices/${userId}/`));
    const existingNames = res.items.map((item) => item.name);
    let counter = 1;
    while (existingNames.includes(`${fileName}.pdf`)) {
      fileName = `Invoice_${invoiceNumber}_${fileName}_${counter}.pdf`;
      storageRef = ref(storage, `salesInvoices/${userId}/${fileName}`);
      counter++;
    }

    try {
      await uploadBytes(storageRef, pdfBlob);
      const fileUrl = await getDownloadURL(storageRef);

      // Save invoice details to Firestore
      await addDoc(collection(db, "salesInvoices", userId, "userInvoices"), {
        userId,
        fileName,
        fileUrl,
        date: invoiceDate,
        invoiceNumber,
        total: totalSum,
        subtotal: subTotalSum,
        vatTotal: vatTotalSum,
        clientName: data.clientName,
      });

      Swal.fire("Success", "PDF saved and uploaded successfully!", "success");
    } catch (error) {
      console.error("Error saving PDF:", error);
      Swal.fire("Error", "Failed to save the PDF. Please try again.", "error");
    }
  }

  async function getNextInvoiceNumber(userId: string): Promise<number> {
    console.log("Getting next invoice number");

    const userInvoicesRef = collection(
      db,
      "salesInvoices",
      userId,
      "userInvoices"
    );
    const q = query(
      userInvoicesRef,
      orderBy("invoiceNumber", "desc"),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    const lastInvoiceNumber =
      querySnapshot.docs.length > 0
        ? parseInt(querySnapshot.docs[0].data().invoiceNumber, 10)
        : 0;
    console.log("Last invoice number:", lastInvoiceNumber + 1);

    return lastInvoiceNumber + 1;
  }

  function handleInput(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files?.length) return;

    const file = e.target.files[0];
    file
      .text()
      .then((str: string) => {
        try {
          if (!(str.startsWith("{") && str.endsWith("}"))) {
            str = atob(str);
          }
          const d = JSON.parse(str);
          const dParsed = TInvoice.parse(d);
          console.info("parsed correctly");
          setData(dParsed);
        } catch (e) {
          console.error(e);
          return;
        }
      })
      .catch((err) => console.error(err));
  }

  return (
    <div className={"download-pdf "}>
      <button
        onClick={handleSavePDF}
        aria-label="Save PDF"
        title="Save PDF"
        className="download-pdf__pdf"
      />
      <p>Save PDF</p>
      <button
        onClick={() => setModalOpen(true)}
        aria-label="Save Template"
        title="Save Template"
        className="download-pdf__template_download "
      />
      <p>Save Template</p>

      <label className="download-pdf__template_upload">
        <input type="file" accept=".json,.template" onChange={handleInput} />
      </label>
      <p>Upload Template</p>
      <br />
      <div>
        <p className="text-large">
          {" "}
          <strong>Saved Templates</strong>
        </p>
        <br />
        <ul>
          {templates.map((template) => (
            <li key={template.url}>
              <button
                onClick={() => loadTemplate(template.url)}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  border: "none",
                  background: "none",
                }}
              >
                {template.name.replace(".template", "")}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography id="modal-title" variant="h6" component="h2">
              Save Template
            </Typography>
            <IconButton onClick={() => setModalOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            label="Template Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              onClick={handleSaveTemplate}
              aria-label="Save Template"
              title="Save Template"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );

  async function loadTemplate(url: string) {
    try {
      const response = await fetch(url);
      const text = await response.text();
      const data = JSON.parse(text);
      setData(data);
    } catch (error) {
      console.error("Error loading template:", error);
    }
  }
};

export default Download;
